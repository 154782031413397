import React from 'react';

import { ISearchResultsControllerProps } from '../../platform/searchResultsControllerStore';
import { Widget } from '../Widget';
import {
  AppSettingsContext,
  HostContext,
  SearchEnvironmentContext,
} from '../../contexts';
import { useHighlightStyle } from './useHighlightStyle';
import { IHostProps } from '@wix/yoshi-flow-editor';

export interface IWidgetRootProps extends ISearchResultsControllerProps {
  id: string;
  host: IHostProps;
}

const WidgetRootWrapper: React.FC<IWidgetRootProps> = React.memo((props) => {
  /*
  With yoshi (either ^6.56.0 or ^6.59.0) upgrade, there were some case of Widget being rendered before
  Controller initializes state - `props` are missing `searchResponse` and
  `useHighlightStyle` hook throws. While it does not reproduce on regular installs,
  it is pretty common when dependant app is installed, e.g. Blog.

  The code below ensures that Widget does not render until controller initializes its state
  */
  if (!props.searchResponse) {
    return null;
  }
  return <WidgetRoot {...props} />;
});

export { WidgetRootWrapper as WidgetRoot };

const WidgetRoot: React.FC<IWidgetRootProps> = React.memo((props) => {
  const {
    apiErrorDetails,
    id,
    isDemoContent,
    locale,
    locationQuery,
    currency,
    onDocumentClick,
    onDocumentTypeChange,
    onPageChange,
    onProductAddToCart,
    onFacetsFilterChange,
    onFacetsFilterReset,
    onQuerySubmit,
    onSortChange,
    onScrollToWidget,
    visibleDocumentTypes,
    scrollToWidget,
    searchSamples,
    searchRequest,
    searchRequestStatus,
    searchResponse,
    searchResponseTotals,
    searchResultsAbsoluteUrl,
    selectedSortOption,
    appSettings,
    viewMode,
    host,
    facets,
    facetFilters,
    shouldContainProductWidget,
  } = useHighlightStyle(props);

  return (
    <div className={id}>
      <AppSettingsContext.Provider value={appSettings}>
        <SearchEnvironmentContext.Provider
          value={{
            isDemoContent,
            viewMode,
            locale,
            currency,
            shouldContainProductWidget,
          }}
        >
          <HostContext.Provider value={host}>
            <Widget
              apiErrorDetails={apiErrorDetails}
              visibleDocumentTypes={visibleDocumentTypes}
              locationQuery={locationQuery}
              onProductAddToCart={onProductAddToCart}
              onDocumentClick={onDocumentClick}
              onDocumentTypeChange={onDocumentTypeChange}
              onFacetsFilterChange={onFacetsFilterChange}
              onFacetsFilterReset={onFacetsFilterReset}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              onQuerySubmit={onQuerySubmit}
              searchRequest={searchRequest}
              searchRequestStatus={searchRequestStatus}
              searchResponse={searchResponse}
              searchResponseTotals={searchResponseTotals}
              searchResultsAbsoluteUrl={searchResultsAbsoluteUrl}
              searchSamples={searchSamples}
              selectedSortOption={selectedSortOption}
              scrollToWidget={scrollToWidget}
              onScrollToWidget={onScrollToWidget}
              facets={facets}
              facetFilters={facetFilters}
            />
          </HostContext.Provider>
        </SearchEnvironmentContext.Provider>
      </AppSettingsContext.Provider>
    </div>
  );
});

import { createContext } from 'react';
import { IWidgetRootProps } from '../components/WidgetRoot';

export type SearchEnvironment = Pick<
  IWidgetRootProps,
  | 'viewMode'
  | 'isDemoContent'
  | 'locale'
  | 'currency'
  | 'shouldContainProductWidget'
>;

export const SearchEnvironmentContext = createContext<SearchEnvironment>(
  {} as SearchEnvironment,
);
